<template>
  <div class="review text-center">
    <h2>Review your progress!!</h2>
    <br>
    <v-row>
      <v-col cols="2" />
      <v-col cols="8">
        <MonthSelector
          :inputMonths="instanceList"
          :activeMonth="activeInstance.instanceid"
          v-on:change="activateInstance"
          v-on:addBlank="newBlankInstance"
          v-on:addTemplate="newTemplateInstance"
        >
        </MonthSelector>
      </v-col>
      <v-col cols="2" />
    </v-row>
    <br>
    <v-row style="padding-right:20px;">
      <v-spacer>
      </v-spacer>
      <v-dialog v-model="dialog" max-width="800px" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded large tile color="primary"
            v-bind="attrs"
            v-on="on"
            @click="iterateTransactFormKey"
            style="margin-bottom: 10px"
            >
            <v-icon large>mdi-plus-circle</v-icon>
            Add Transaction
          </v-btn>
        </template>
        <TransactForm
            :instanceId="activeInstance.instanceid"
            :key="transactFormKey"
          v-on:cancel="dialog = false"
          v-on:save="saveTransact"
        >
        </TransactForm>
      </v-dialog>
    </v-row>
    <v-row>
      <v-spacer />
      <h2>{{ activeInstanceTitle }}</h2>
      <v-spacer />
    </v-row>
    <v-progress-linear v-if="upsertTransactRunning.status" indeterminate />
    <v-row>
      <v-col cols="12" md="3">
        <h3>Categories</h3>
        <v-data-table
          :headers="headers"
          :items="monthlyCategoryList"
          item-key="budgetcategoryinstanceid"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          show-select
          v-on:input="updateSelectedCategories"
          v-on:click:row="selectCategoryRow"
          v-model="selectedRows"
        >
        </v-data-table>
      </v-col>
      <v-col cols="12" md="9">
        <h3>Budget Transactions</h3>
        <v-data-table
          :headers="transactHeaders"
          :items="selectedBudgetTransactList"
          item-key="Name"
          class="elevation-1"
          :search="transactSearch"
          v-on:click:row="selectTransactRow"
        >
          <template v-slot:top>
            <v-row>
              <v-spacer/>
              <v-text-field
                  label="Search"
                  v-model="transactSearch"
                  prepend-inner-icon="mdi-magnify"
                  style="max-width:300px"
              />
            </v-row>
          </template>
        </v-data-table>
        <v-dialog
          v-model="editTransactDialog"
          max-width="800px" persistent
          v-if="activeTransact && activeTransact.transactid"
        >
          <TransactForm
              :instanceId="activeInstance.instanceid"
              :input-type="activeTransact.transactType"
              :id="activeTransact.transactid.toString()"
              :name="activeTransact.name"
              :description="activeTransact.description"
              :date="activeTransact.date"
              :datePost="activeTransact.datePost"
              :amount="activeTransact.amount"
              :tags="activeTransact.tags"
              :to-account="activeTransact.toAccount"
              :from-account="activeTransact.fromAccount"
              :transact-attr-link-id="activeTransact.primarytransactattrlink[0].transactattrlinkid.toString()"
              :budgets="activeTransact.budgets"
              :key="activeTransact.transactid"
              v-on:cancel="editTransactDialog = false"
              v-on:save="saveTransact"
              v-on:delete="deleteTransact"
              />
        </v-dialog>
      </v-col>
    </v-row>
    <v-progress-linear v-if="upsertTransactRunning.status" indeterminate />
    <v-snackbar v-if="errorSnackbar" v-model="errorSnackbar" :timeout="snackbarTimeout">
      <slot>
        ERROR Submitting Transaction
      </slot>
    </v-snackbar>
    <v-snackbar v-if="successSnackbar" v-model="successSnackbar" :timeout="snackbarTimeout">
      <slot>
        Success! Transaction created
      </slot>
    </v-snackbar>
  </div>
</template>

<script>
import TransactForm from '@/components/TransactForm.vue'
import MonthSelector from '@/components/MonthSelector.vue'

import {Action, Getter} from '@/store/transact/types';
import {Action as BudgetAction, Getter as BudgetGetter} from '@/store/budgetTransact/types'
import {Action as ProfileAction, Getter as ProfileGetter} from '@/store/profile/types'
import {Action as InstanceAction, Getter as InstanceGetter} from '@/store/instance/types'
import {Action as CategoryAction, Getter as CategoryGetter} from '@/store/budgetCategory/types'

function valueInArray(
    value,
    array,
    arrayValueCallback = (item) => item,
) {
  for (var i=0; i < array.length; i++)
  {
    if (value === arrayValueCallback(array[i])) {
      return true;
    }
  }
  return false;
}

export default {
  name: 'Review',
  metaInfo: {
    title: 'Review',
  },
  components: {
    TransactForm,
    MonthSelector,
  },
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    snackbarTimeout: 5000,
    selectedRows: [],
    transactFormKey: 0,
    dialog: false,
    editTransactDialog: false,
    headers: [
      { text: 'Category', value: 'name' },
      { text: 'Budgeted', value: 'amount'},
    ],
    transactSearch: null,
    transactHeaders: [
      { text: 'Date', value: 'transact.transactdate'},
      { text: 'Name', value: 'transact.name'},
      { text: 'Budget Description', value: 'description'},
      { text: 'Description', value: 'transact.description'},
      { text: 'Category', value: 'budgetcategoryinstance.budgetcategory.name' },
      { text: 'Amount', value: 'amount'},
    ],
    categories: [
      { Remaining: 1.23, Category: 'Home'},
      { Remaining: 10.23, Category: 'Miscellaneous'}
    ],
    transactions: [
      { Name: 'One', Amount: 1.23},
      { Name: 'Two', Amount: 2.34},
    ],
    selectedCategories: [],
  }),
  methods: {
    updateSelectedCategories: function(selected) {
      console.log(selected)
      this.selectedCategories = selected
    },
    iterateTransactFormKey: function () {
      this.transactFormKey = this.transactFormKey + 1
      // ensure nothing is loading. This is a new transaction
      this.$store.dispatch(`${[Action.CompleteActiveTransact]}`)
    },
    saveTransact: function(transact) {
      // TODO - would be great to check for duplicates here...
      console.log(transact)
      this.$store.dispatch(`${[Action.UpsertTransact]}`, transact).then(
          () => {
            // refresh / update the list of budget transactions!
            this.$store.dispatch(
                `${[BudgetAction.BudgetTransactList]}`,
                {instanceid: this.activeInstance.instanceid}
            )
          })
      // TODO - catch errors!
      this.dialog = false
      this.editTransactDialog = false
    },
    deleteTransact: function(transactid) {
      console.log(transactid)
      this.$store.dispatch(`${[Action.DeleteTransact]}`, {transactid: transactid}).then(
          () => {
            // refresh / update the list of budget transactions!
            this.$store.dispatch(
                `${[BudgetAction.BudgetTransactList]}`,
                {instanceid: this.activeInstance.instanceid}
            )
          }
      )
      // TODO - catch errors!
      this.dialog = false
      this.editTransactDialog = false
    },
    selectCategoryRow: function(arg1, arg2) {
      console.log(arg1)
      console.log(arg2)
      arg2.select(!arg2.isSelected)
    },
    selectTransactRow: function(arg1, arg2) {
      this.editTransactDialog = true
      this.$store.dispatch(`${[Action.ActivateTransact]}`, {transactid: arg1.transactid})
      console.log(arg1)
      console.log(arg2)
    },
    syncInstanceTemplate: function() {
        this.$store.dispatch(
            `${[InstanceAction.SyncInstanceTemplate]}`,
            {
                templateid: this.finpicProfileAlt.templateInstance,
                instanceid: this.activeInstance.instanceid,
            }
        )
    },
    activateInstance: function(month) {
      this.$store.dispatch(`${[CategoryAction.FetchMonthlyCategoryList]}`, {instanceid: month.id})
      this.$store.dispatch(`${[BudgetAction.BudgetTransactList]}`, {instanceid: month.id})
    },
    activateTemplate: function() {
      this.$store.dispatch(`${[CategoryAction.FetchMonthlyCategoryList]}`, {instancename: 'Template'})
    },
    newBlankInstance: function(input) {
      this.$store.dispatch(
          `${[InstanceAction.CreateInstance]}`,
          {
              finpicid: this.activeFinpic.finpicid,
              input_type: 'month',
              input_date: this.makeDateString(input.month),
          }
          )
    },
    newTemplateInstance: function(input) {
        this.$store.dispatch(
            `${[InstanceAction.CreateInstanceFromTemplate]}`,
            {
                finpicid: this.activeFinpic.finpicid,
                input_type: 'month',
                input_date: this.makeDateString(input.month),
                templateid: this.finpicProfileAlt.templateInstance,
            }
        )
    },
    makeDateString: function(inputDate) {
      const localDate = new Date(inputDate)
      const date = [ localDate.getFullYear(), localDate.getMonth() + 1, localDate.getDate() ]

      if (date[1] < 10) date[1] = `0${date[1]}`
      if (date[2] < 10) date[2] = `0${date[2]}`
      console.log(date)

      return date.join("-")
    },
  },
  computed: {
    upsertTransactRunning() {
      return this.$store.getters[`${[Getter.GetUpsertTransactRunning]}`]
    },
    monthlyCategoryList() {
      let rawList = this.$store.getters[`${[CategoryGetter.GetMonthlyCategoryList]}`]
      rawList = rawList.filter(
          (x) => !!x.budgetcategoryinstanceid
      )
      return rawList
    },
    instanceList() {
      const baseList = this.$store.getters[`${[InstanceGetter.GetMonthlyInstanceList]}`]
      return baseList.map(
        (inst) => {
          return {id: inst.instanceid, date: new Date(inst.effective + ' 00:00:00')}
        }
      )
    },
    activeTransact() {
      return this.$store.getters[`${[Getter.GetActiveTransact]}`]
    },
    activeInstance() {
      return this.$store.getters[`${[InstanceGetter.GetActiveInstance]}`]
    },
    activeInstanceTitle() {
            if (this.activeInstance.name === 'Template') {
                return 'Template'
            } else {
                // TODO: protect against null and format
                return this.activeInstance.effective
            }
    },
    activeFinpic() {
      let item = this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
      console.log(item)
      return item
    },
    finpicProfileAlt() {
      return this.$store.getters[`${[ProfileGetter.getFinpicProfileAlt]}`]
    },
    finpicProfile() {
      return this.$store.getters[`${[ProfileGetter.getFinpicProfile]}`]
    },
    budgetTransactList() {
      let res = this.$store.getters[`${[BudgetGetter.GetBudgetTransactListPosNeg]}`]
      console.log("helo: "); console.log(res)
      return res
    },
    selectedBudgetTransactList() {
      let filteredList = this.budgetTransactList
      if (this.selectedCategories.length > 0) {
         filteredList = this.budgetTransactList.filter(
            (bt) => {
              return valueInArray(
                  bt.budgetcategoryinstanceid,
                  this.selectedCategories,
                  (cat) => {return cat.budgetcategoryinstanceid}
              )
            }
        )
      }
      return filteredList
    },
  },
  watch: {
    upsertTransactRunning(val, prev) {
      if(prev.status && !val.status) {
        // it just stopped
        if (val.result === "success") {
          this.successSnackbar = true
        } else if (val.result === "error") {
          this.errorSnackbar = true
        } else {
          // presume error
          this.errorSnackbar = true
        }
      }
    },
  },
  mounted() {
    var activate = null;
    let res = this.$store.dispatch(`${[ProfileAction.loadUserProfile]}`)

    res.then(() => {
      console.log(this.activeInstance)
      if (!this.activeInstance.instanceid) {
        // TODO: check for instanceid to be 'Template'? That is not allowed...
        activate = 'latest'
      } else {
        // already have an activeInstance... reset state
        this.$store.dispatch(`${[BudgetAction.BudgetTransactList]}`, {instanceid: this.activeInstance.instanceid})
      }
      this.$store.dispatch(`${[InstanceAction.FetchMonthlyInstanceList]}`, {activate: activate, finpicid: this.activeFinpic.finpicid});
    })
    },
  }

</script>
